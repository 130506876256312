import React from 'react'
import Layout from '../components/layout'
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const ClaimsAudits = () => (
  <Layout>
    <div className="mdl-grid">
      <div className="mdl-cell mdl-cell--12-col" id="ClaimsAudits">
        <div className="mdl-card mdl-shadow--2dp" style={{ width: 'auto' }}>
          <div className="mdl-card__title mdl-card--expand">
            <div className="mdl-cell mdl-cell--12-col">
              <h2 className="page-header" style={{ padding: '5px' }}>
                Workers' Compensation, Liability, and Property Claims Audits
              </h2>
              <p>
                SCD has performed numerous claims audits for its clients. These
                claims audits have been of{' '}
                <u>
                  workers' compensation, casualty / liability, property, or
                  collegiate athlete medical insurance programs.
                </u>
              </p>
              <p>
                We compare the quality of claims management performance against
                one or more documented procedures:
              </p>
              <ul>
                <li>
                  Leading industry practices, which are activities that
                  insurers, TPAs, and self-administered programs have developed
                  that assist the adjusters and supervisors in working toward
                  optimal outcomes.
                </li>
                <li>
                  Contractual requirements, either in the form of the formal
                  contract or in the form of special service instructions that
                  have been developed that clarify roles and responsibilities of
                  the client and the claims administrator.
                </li>
              </ul>
              <img
                className="aboutImg"
                style={{ maxWidth: '400px', float: 'right' }}
                src="/Audit_checklist.jpg"
                alt="Audit checklist"
              />
              <p>
                Claims audits provide valuation information to improve the level
                of service and ensure that the claims administrators know that
                their activities are being fairly and objectively measured.{' '}
              </p>

              <p>
                We will also review loss and management reports, procedures
                manuals, and other documents. These will provide us with
                additional background information regarding your program.
              </p>
              <p>
                We will randomly select a sample of claims for the claims audit.
                The sample will include claims from each line of business, claim
                office, or adjuster to meet your needs.
              </p>

              <p>
                We have developed a claims audit template that allows us to
                evaluate how a claims administrator's activities compare to
                these metrics. We review the claim files to assess the
                performance compared to these activity-based requirements. We
                also review the outcomes of the claims management work based on
                the action plans developed by the claims personnel.
              </p>

              <p>
                Based on our evaluation, we provide our clients with a draft
                report which measures the claims administrator's performance in
                several critical claims management areas, and identifies any
                gaps between its performance and the standards. We then provide
                recommendations to close any gaps. We assist our clients in
                setting priorities for improvement.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
export default ClaimsAudits
